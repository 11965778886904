// extracted by mini-css-extract-plugin
export const blogHeroContainer = "blog-module__blogHeroContainer--11mUN";
export const pageTitle = "page-title";
export const blogTeaserList = "blog-module__blogTeaserList--3JmxJ";
export const blogTeaserArticle = "blog-module__blogTeaserArticle--1LEXe";
export const mediaContainer = "media-container";
export const media = "media";
export const content = "content";
export const meta = "meta";
export const date = "date";
export const author = "author";
export const title = "title";
export const excerpt = "excerpt";
export const blogFeaturedPost = "blog-module__blogFeaturedPost--XEm__";