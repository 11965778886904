import React from 'react'
import { graphql, Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'

import MainContainer from '../container/main'
import Subheadline from '../components/content-subheadline'
import SEO from '../components/seo'
import Container from '../components/container'
import { blogTeaserArticle, blogHeroContainer, blogTeaserList, blogFeaturedPost } from './blog.module.scss'

const TeaserItem = ({ post }) => (
  <Link className={blogTeaserArticle} to={post.node.frontmatter.slug}>
    {console.log('REMAKR', post)}
    <div className="media-container">
      {post.node.frontmatter.featuredImage ? (
        <GatsbyImage className="media" image={post.node.frontmatter.featuredImage.childImageSharp.gatsbyImageData} alt={post.node.frontmatter.title} />
      ) : (
        'MISSING FEATURE IMAGE'
      )}
    </div>
    <div className="content">
      <div className="meta">
        {post.node.frontmatter.date && <span className="date">{post.node.frontmatter.date}</span>}
        {post.node.frontmatter.author && <span className="author">{post.node.frontmatter.author}</span>}
      </div>
      <span className="title">{post.node.frontmatter.title}</span>
      <span className="excerpt">{post.node.frontmatter.excerpt}</span>
    </div>
  </Link>
)

const FeaturedItem = ({ post }) => (
  <Link className={blogFeaturedPost} to={post.node.frontmatter.slug}>
    {console.log('REMAKR', post)}
    <div className="media-container">
      {post.node.frontmatter.featuredImage ? (
        <GatsbyImage className="media" image={post.node.frontmatter.featuredImage.childImageSharp.gatsbyImageData} alt={post.node.frontmatter.title} />
      ) : (
        'MISSING FEATURE IMAGE'
      )}
    </div>
    <div className="content">
      <div className="meta">
        {post.node.frontmatter.date && <span className="date">{post.node.frontmatter.date}</span>}
        {post.node.frontmatter.author && <span className="author">{post.node.frontmatter.author}</span>}
      </div>
      <span className="title">{post.node.frontmatter.title}</span>
      <span className="excerpt">{post.node.frontmatter.excerpt}</span>
    </div>
  </Link>
)

const BlogPage = ({ data }) => (
  <MainContainer>
    <SEO title="Blog" />
    <Container>
      <section className={blogHeroContainer}>
        <h1 className="page-title">Blog</h1>
      </section>

      <section className={blogTeaserList}>
        {data.allMarkdownRemark.edges.map((post, index) => index === 0 && <FeaturedItem post={post} key={index} />)}
        <Subheadline headline="Latest Articles" />
        {data.allMarkdownRemark.edges.map((post, index) => index !== 0 && <TeaserItem post={post} key={index} />)}
      </section>
    </Container>
  </MainContainer>
)

export default BlogPage

export const query = graphql`
  {
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }, limit: 1000, filter: { frontmatter: { slug: { regex: "/blog/" } } }) {
      edges {
        node {
          frontmatter {
            slug
            title
            featuredImage {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
            author
            date
            excerpt
          }
        }
      }
    }
  }
`
